import type { RouteLocationNamedRaw } from "vue-router";

import { routeNames } from "@/router/route-names";
import type { RouteMetaConfig } from "@/types/router";
import { buildModalRoute } from "@/utils/modal-utils";

export const routesDeposit: RouteMetaConfig[] = [
  {
    path: "/deposits",
    name: routeNames.depositOverview,
    component: () => import("./views/DepositListView.vue"),
    meta: {
      requiresAuth: true,
      permission: "products.view",
      listFilterName: "products",
    },
    children: [
      buildModalRoute(
        {
          name: routeNames.depositCreate,
          path: "create",
          meta: {
            requiresAuth: true,
            permission: "products.create",
          },
          component: () => import("./views/DepositCreateFormView.vue"),
        },
        { fullscreen: true }
      ),
    ],
  },
  {
    path: "/deposits/:id(\\d+)",
    name: routeNames.depositDetails,
    component: () => import("./views/DepositDetailView.vue"),
    meta: {
      requiresAuth: true,
      permission: "products.view",
    },
    children: [
      buildModalRoute(
        {
          path: "edit/general",
          name: routeNames.depositEditGeneral,
          meta: {
            requiresAuth: true,
            permission: "products.update",
          },
          component: () => import("./views/DepositEditGeneralForm.vue"),
        },
        { fullscreen: false }
      ),
      buildModalRoute(
        {
          path: "edit/tax",
          name: routeNames.depositEditTax,
          meta: {
            requiresAuth: true,
            permission: "products.update",
          },
          component: () => import("./views/DepositEditTaxForm.vue"),
        },
        { fullscreen: false }
      ),
    ],
  },
];

export const depositRoutesFactory = {
  depositDetail(id: number | string): RouteLocationNamedRaw {
    return {
      name: routeNames.depositDetails,
      params: {
        id,
      },
    };
  },
};
